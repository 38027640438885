<template>
	<div class="app-container">
    <div class="header">
      <h1>Apps Collection</h1>
      <a href="https://github.com/innenrinde" target="_blank">
        <font-awesome-icon :icon="['fab', 'fa-github']" />
      </a>
    </div>

    <item-description
      v-for="item in apps"
      :key="item"
      :title="item.title"
      :description="item.description"
      :languages="item.languages"
      :technologies="item.technologies"
      :url="item.url"
      :source="item.source"
    />

    <div class="footer">
      <div class="left">(c) 2025 vladnadrian@yahoo.com</div>
      <div class="right">
        <a href="https://github.com/innenrinde" target="_blank">@innenrinde</a>
      </div>
    </div>

  </div>

</template>

<script>

import ItemDescription from "@/components/ItemDescription.vue";

export default {
  name: 'App',
  components: {
    ItemDescription,
  },
	data() {
		return {
      apps: [
        {
          title: "Content administration",
          description: "Using Symfony Security Bundle and VueJs, we can manage content of a database table.<br>Doctrine Bundle for access database.<br>Different types of users with different access rights.<br><br>Working progress... but you can try with:<br>- username: admin@test.com<br>- password: admin",
          url: "http://admin.powerfullapp.ro",
          source: "https://github.com/innenrinde/Admin",
          languages: ['php', 'sql', 'js', 'css'],
          technologies: ['symfony', 'mysql', 'vuejs'],
        },
        {
          title: "kNN",
          description: "Having a front-end searching list of objects to display results with relevance better than a simple word matching based on standards JavaScript methods.<br>More details about that can be read following this link: <a href='https://www.linkedin.com/pulse/c%C4%83ut%C3%A2nd-inspira%C8%9Bie-algoritmul-knn-vlad-adrian-ph04f' target='_blank'>simple article</a>",
          url: "http://knn.powerfullapp.ro",
          source: "https://github.com/innenrinde/kNN",
          languages: ['js', 'css'],
          technologies: ['vuejs'],
        },
        {
          title: "Calendar",
          description: "Organize your daily events by hours.<br>Switch between \"Month\", \"Week\" or \"Day\" view (a study of strategy design pattern).<br>Nice \"sailing\" from a date to date using arrows or an infinite scroll.<br>Multi locale support.<br>Easy customizable scss template.",
          url: "http://calendar.powerfullapp.ro",
          source: "https://github.com/innenrinde/Calendar",
          languages: ['js', 'css'],
          technologies: ['vuejs'],
        },
        {
          title: "Tree",
          description: "Multi level tree with expandable children.<br>Huge size. Do you want to manage a tree with 100k nodes?<br>Use providers pattern to process input data obtaining a full recursively parent-child relations.",
          url: "http://tree.powerfullapp.ro",
          source: "https://github.com/innenrinde/Tree",
          languages: ['js', 'css'],
          technologies: ['vuejs'],
        },
        {
          title: "ShopCarts",
          description: "Example of a JavaScript objects to simulate an on-line shop cart.<br>Step by step, by different levels, build a shop card from ES5 to ES6.<br>Run tests to avoid bugs and logical anomalies.",
          url: "https://github.com/innenrinde/ShopCarts",
          source: "https://github.com/innenrinde/ShopCarts",
          languages: ['js'],
          technologies: ['vitest'],
        },
        {
          title: "ChatOpenAI",
          description: "An example of an on-line chat interface using OpenAI and tectalic/openai bundle.<br>Local settings for training text.<br>Keep message history.",
          url: "https://github.com/innenrinde/ChatOpenAI",
          source: "https://github.com/innenrinde/ChatOpenAI",
          languages: ['php', 'js', 'css'],
          technologies: ['symfony', 'mysql'],
        },
        {
          title: "EventBus",
          description: "A tiny library to emit callbacks to the specified events.<br>Emits one or more events using multiple channels.",
          url: "https://www.npmjs.com/package/@innenrinde/event-bus",
          source: "https://github.com/innenrinde/event-bus",
          languages: ['js'],
          technologies: ['vitest'],
        },
        {
          title: "Drag & Drop",
          description: "Vertical or horizontal drag & drop list.<br>One single VueJs component to display a vertical or an horizontal list with items which can be sorted by drag and drop.",
          url: "http://dragdrop.powerfullapp.ro",
          source: "https://github.com/innenrinde/drag-and-drop",
          languages: ['js', 'css'],
          technologies: ['vuejs'],
        },
        {
          title: "Stepper",
          description: "A simple VueJs component to navigate between steps from a custom list.<br>Like the steps of a shopping cart.<br>Like the steps of a registration process.<br>Steps with flags for different statuses: warning, error etc.",
          url: "http://stepper.powerfullapp.ro",
          source: "https://github.com/innenrinde/Stepper",
          languages: ['js', 'css'],
          technologies: ['vuejs'],
        },
        {
          title: "Blog",
          description: "An old blog content manager.<br>With admin dashboard interface.<br>With admin section to edit pages, categories and articles.<br>Widgets managed using an observer design pattern.",
          url: "http://editorials.chattoir.com",
          source: "https://github.com/innenrinde/blog",
          languages: ['js', 'css', 'php'],
          technologies: ['codeigniter', 'mysql'],
        }
      ]
		}
	},
	methods: {

	}
}
</script>

<style lang="scss">

@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

html, body {
	margin: 0;
	background-color: #efefef;
	height: 100%;
}

#app {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6em;
  font-size: 14px;
  color: #435160;
	height: 100%;
  margin: 0 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-container {
  height: 100%;
  width: 100%;
  max-width: 800px;
	display: flex;
	flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 0 10px 0;

  a {
    font-size: 20px;
    color: #a5a5a5;
  }

  h1 {
    margin: 0;
    width: 100%;
  }
}

.footer {
  padding: 50px 0 50px 0;
  display: flex;
  flex-direction: row;

  .left,
  .right {
    width: 50%;
  }

  .right {
    text-align: right;
  }

  a {
    color: #435160;
    text-decoration: none;
  }

  a:hover {
    color: #000;
  }
}

</style>


